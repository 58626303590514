.header-nav-btn {
  align-items: center;
  background-color: #707070;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: .875rem;
  font-weight: 700;
  justify-content: center;
  padding: .6em .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: #606060;
  }
}