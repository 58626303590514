// Grid utilities to Bootstrap grid system based on flex

.col-gap {
  --col-gap: 1rem;

  column-gap: var(--col-gap);
}

.row-gap {
  --row-gap: 1rem;

  row-gap: var(--row-gap);
}

.auto-grid {
  --column-size: clamp(160px, calc(100px + 15vmin), 320px);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, var(--column-size)), 1fr));
  gap: 1rem;
}