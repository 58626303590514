$theme-colors: (
  "secondary": #707070,
  "light": #dfdfdf,
  "warning": #F2EBD6,
  "danger": #ec557a,
  "muted": #e6e6e6,
  "info": #D2EAFF,
  "lighter": #EFEFEF,
  "background": #F0F0F0,
);

$focus-scroll-offset: 233px;

// Type size
$font-size-base: 1rem; // 16px
$font-size-sm: 0.875rem; // 14px
$font-size-lg: 1.125rem; // 18px
$small-font-size: 0.75rem; // 12px

// HLL colors
$hll-orange: #eb6400;
$hll-beige: #d5ba8c;
$hll-dark-gray: #4e4e4e;
//$hll-gray: #8b8b8b;
//$hll-light-gray: #EFEFEF;
$hll-warning: #F2EBD6;
$hll-success: #F5E7DC;

$orange: $hll-orange;
$white: #fff;
$black: #000;
$info-bkg: #D2EAFF;
$success: rgb(0, 217, 126);


// Grayscale (taken from Dashkit)
// TODO Specify good defaults for HLL
//$white: #FFFFFF;
// $gray-100: #f9fbfd;
// $gray-200: #edf2f9;
// $gray-300: #e3ebf6;
// $gray-400: #d2ddec;
// $gray-500: #b1c2d9;
// $gray-600: #95aac9;
// $gray-700: #a7a5a5;
// $gray-800: #3b506c;
// $gray-900: #283e59;

// $gray-100: #FAFAFA;

$gray-100: #F0F0F0;

$gray-200: #F5F5F5;
$gray-300: #EFEFEF;
$gray-400: #dfdfdf;
$gray-500: #C5C5C5;
$gray-600: #707070;
$gray-700: #5B5B5B;
$gray-800: #4E4E4E;
$gray-900: #353535;

// Colors
$primary: $orange;
// $body-bg-color: #fafafa;
$body-bg-color: $gray-100;
$body-bg-color-light: #f9f9f9;
// $border-color: $gray-400;
$border-color: #EAEAEA;
$border-color-dark: $gray-400;
$headings-color: #555;
$text-muted: $hll-dark-gray;
$header-bg-color: $gray-800;
$indicators: #d9d9d9;


$gray-600-dark: #244166;
$gray-700-dark: #1e3a5c;
$gray-800-dark: #152e4d;
$gray-900-dark: #132a46;
$black-dark: #12263f;

// Shadows
$box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.05), 0 1px 4px 0 rgba(0, 0, 0, 0.05),
  0 4px 6px 0 rgba(0, 0, 0, 0.05);
// $box-shadow:    0 0 5px 0 rgba(0, 0, 0, 0.1); // Original
$box-shadow-sm: 0 0px 1px 0 rgba(0, 0, 0, 0.025), 0 0px 3px 0 rgba(0, 0, 0, 0.0375),
0 0px 6px 0 rgba(0, 0, 0, 0.0375);
$box-shadow-lg: 0 0 7px 0 rgba(0, 0, 0, 0.1);
$box-shadow-inset: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);

// Cards
$card-box-shadow: $box-shadow-sm;
$card-border-radius: 4px;
$card-border-width: 1px;
$card-border-color: $border-color;

// Tables
$table-border-color: $gray-400;

$read-only-bkg: $gray-400;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1400px
);

// Misc
$all-text-inputs: 'input[type="color"]', 'input[type="date"]',
  'input[type="datetime"]', 'input[type="datetime-local"]',
  'input[type="email"]', 'input[type="month"]', 'input[type="number"]',
  'input[type="password"]', 'input[type="search"]', 'input[type="tel"]',
  'input[type="text"]', 'input[type="time"]', 'input[type="url"]',
  'input[type="week"]', "input:not([type])", "textarea";

$input-border-radius: 4px;
$border-radius: $input-border-radius;

// Paths used by Dashkit
$path-to-fonts: "../../fonts";
$path-to-img: "../../img";

// Charts
$chart-colors: (
  "gray-300": $gray-300,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "primary-100": "#ddd",
  "primary-300": "#ccc",
  "default-color": $gray-600,
  "default-font-color": $gray-600,
  "arc-border-color": $white,
  "arc-hover-border-color": $white,
  "grid-line-color": $gray-300,
  "grid-line-zero-line-color": $gray-300
);

// Availability color for Machine status
$bird-flower: #d5c917;
$tradewind: #69c0a7;
$mandy: #ec557a;
$lemon: #f9ea0b;

$on-control: $lemon;
$available: $tradewind;
$not-available: $mandy;

$periwinkle-gray: #d8e0f0;
$kashmir-blue: #4e6594;

$machine-status-info-bkg: $periwinkle-gray;
$machine-status-warning-bkg: $mandy;
$machine-status-info-txt: $kashmir-blue;
$border-light-color: rgba(0, 0, 0, 0.08);
