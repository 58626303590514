.react-datepicker-wrapper {
  width: 100%;

  [type="text"] {
    border: 1px solid $gray-300;
    height: 50px;
    padding: 0 15px;
    font-weight: 400;
  }
}

.react-datepicker {
  font-family: $font-family-base;
  border-radius: 5px;
  border-color: $gray-400;
}

.react-datepicker__header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-color: $gray-400;
  background-color: $gray-100;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $primary;
}

.react-datepicker__triangle {
  display: none;
}
