// Abstracts: variables, mixins etc
@import "assets/styles/abstracts/animation";
@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";
@import "assets/styles/abstracts/helpers";
@import "assets/styles/abstracts/utilities";

// Base
@import "assets/styles/base/fonts";

// Dashkit theme
@import "assets/styles/themes/dashkit";

// Components
@import "assets/styles/components/header-nav-button";
@import "assets/styles/components/card";
@import "assets/styles/components/map";
@import "assets/styles/components/orders";
@import "assets/styles/components/accordion";
@import "assets/styles/components/datepicker";
@import "assets/styles/components/modal";
@import "assets/styles/components/table-skeleton-loader";
@import "assets/styles/components/push-notifications";
@import "assets/styles/components/machines";
@import "assets/styles/components/machine";
@import "assets/styles/components/nav-off-canvas";
@import "assets/styles/components/table";
@import "assets/styles/components/returns";
@import "assets/styles/components/update-notification-popup.scss";
@import "assets/styles/components/onboarding.scss";
@import "assets/styles/components/shortcut.scss";
@import "assets/styles/components/machine-search.scss";
@import "assets/styles/components/hll-breadcrumb.scss";
@import "assets/styles/components/technical-specification-grid.scss";
@import "assets/styles/components/search.scss";
@import "assets/styles/components/customer-machine-category-grid.scss";
@import "assets/styles/components/customer-single-machine-model.scss";
@import "assets/styles/components/customer-machine-lists.scss";
@import "assets/styles/components/category-card";
@import "assets/styles/components/product-card";
@import "assets/styles/components/category-grid";
@import "assets/styles/components/header";
@import "assets/styles/components/badge";
@import "assets/styles/components/button";



// Layout
@import "assets/styles/layout/footer";
@import "assets/styles/layout/forms";
@import "assets/styles/layout/header";
@import "assets/styles/layout/modal-sheet";
@import "assets/styles/layout/grid";

// Pages
@import "assets/styles/pages/login";
@import "assets/styles/pages/create-account";
@import "assets/styles/pages/maintenance";

:root {
  --focus-scroll-offset: 0px;
}

html,
body {
  background-color: $body-bg-color;
  overflow-x: hidden;

  &.disable-scroll {
    overflow: hidden;
  }
}

body {
  position: relative;
  background-color: $body-bg-color;
  overscroll-behavior: contain; // Disabled Pull-to-refresh

  &.virtual-keyboard-active {
    overflow: hidden;

    &.is-ios {
      .app {
        position: fixed;
        width: 100%;
        //top: env(--focus-scroll-offset);
        animation: animateOnFocus 0.4s ease forwards;
        padding: 0 !important;
      }
    }
  }

  @keyframes animateOnFocus {
    0% {
      top: 0%;
    }
    100% {
      top: 233px;
    }
  }
}

.app {
  background-color: $body-bg-color;

}

.app-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 5;
}

.bg-dark-theme {
  background-color: $header-bg-color;
  color: $white;
}

.overflow-hidden {
  overflow-x: hidden;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  color: $black;
  font-weight: 700;
}

button,
a {
  color: inherit;
}

a:hover {
  color: $primary;
  text-decoration: none;
}


.pagination-tabs .page-link,
.pagination-tabs .page {
  margin-top: 0px;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
//select:focus,
textarea,
input[role="combobox"]{
  font-size: 16px;
  font-weight: 400;
  //background-color: $body-bg-color;
  //border: 1px solid $gray-300;
  border-radius: $input-border-radius;
  color: $black;
  padding: 0.75em 1.25em;
}

.container-fluid-max-width {
  max-width: 1400px;
}

.form-control:not(.custom-select) {
  padding-right: 1.25em;
}

.btn-go-back {
  width: 40px;
  height: 40px;
}

.svg-icon-wrapper {
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  img,
  svg {
    max-width: 100%;
    height: auto;
  }

  &.text-black {
    img,
    svg {
      path {
        stroke: $black;
      }
    }
  }

  &.text-white {
    svg {
      path {
        stroke: $white;
      }
    }
  }

  &.text-muted {
    svg {
      path {
        stroke: $gray-800;
      }
    }
  }

  &.text-danger {
    svg {
      path {
        stroke: #ec557a;
      }
    }
  }

  &.text-success {

    &:not(.with-multiple-paths) {
      svg {
        path {
          stroke: $success;
        }
      }
    }

    &.with-multiple-paths {
      svg {
        path {
          stroke: $success;
          fill: $success;

          + path {
            stroke: $white;
          }
        }
      }
    }
  }

  &.text-danger {

    &:not(.with-multiple-paths) {
      svg {
        path {
          stroke: #ec557a;
        }
      }
    }

    &.with-multiple-paths {
      svg {
        path {
          stroke: #ec557a;
          fill: #ec557a;

          + path {
            stroke: $white;
          }
        }
      }
    }
  }
}

.hyphenate {
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
}

.priceBig {
  font-size: 3rem;
}

.border {
  &--dashed {
    border-style: dashed !important;
  }
}

// https://gist.github.com/cvan/6c022ff9b14cf8840e9d28730f75fc14
$fallbackPadding: 24px;
@supports (padding-top: constant(safe-area-inset-top)) {
  .js-app-header {
     > * {
        padding-top: constant(safe-area-inset-top, $fallbackPadding);
     }
  }

  .js-app-off-canvas {
    padding-top: constant(safe-area-inset-top, $fallbackPadding);
  }

  .js-app-action-footer {
    padding-bottom: constant(safe-area-inset-bottom, $fallbackPadding);
  }

  .modal .modal-dialog[fullscreen="true"] .modal-content {
    padding-top: constant(safe-area-inset-top, $fallbackPadding);
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  .js-app-header {
    > * {
       padding-top: env(safe-area-inset-top, $fallbackPadding);
    }
  }

  .js-app-off-canvas {
    padding-top: env(safe-area-inset-top, $fallbackPadding);
  }

  .js-app-action-footer {
    padding-bottom: env(safe-area-inset-bottom, $fallbackPadding);
  }

  .modal .modal-dialog[fullscreen="true"] .modal-content {
    padding-top: env(safe-area-inset-top, $fallbackPadding);
  }

}

@media (display-mode: fullscreen) {
  .js-app-header {
    > * {
       padding-top: env(safe-area-inset-top, $fallbackPadding);
    }
  }

  .js-app-off-canvas {
    padding-top: env(safe-area-inset-top, $fallbackPadding);
  }

  .js-app-action-footer {
    padding-bottom: env(safe-area-inset-bottom, $fallbackPadding);
  }

  .modal .modal-dialog[fullscreen="true"] .modal-content {
    padding-top: env(safe-area-inset-top, $fallbackPadding);
  }
}

@media (display-mode: standalone) {
  .js-app-header {
    > * {
       padding-top: env(safe-area-inset-top, $fallbackPadding);
    }
  }

  .js-app-off-canvas {
    padding-top: env(safe-area-inset-top, $fallbackPadding);
  }

  .js-app-action-footer {
    padding-bottom: env(safe-area-inset-bottom, $fallbackPadding);
  }

  .modal .modal-dialog[fullscreen="true"] .modal-content {
    padding-top: env(safe-area-inset-top, $fallbackPadding);
  }
}
