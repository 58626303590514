// Search bar
// ----------

.search-bar {
  --outer-margin-x: 20px;

  position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 400;

	box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);

	visibility: hidden;
	opacity: 0;
	transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
		visibility 0s linear 0.6s;

	&.is-open {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	}

	ul,
	ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.search-bar-form {
	position: relative;
	padding: var(--outer-margin-x);
	max-width: 1400px;
  margin-inline: auto;
}

.search-bar-field {
	position: relative;
	width: auto;
	z-index: 40;
}

.search-bar-icon {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	padding: 0.5rem;
	color: #e0e0e0;
	// opacity: 0.5;
	cursor: pointer;
}

.search-bar-close-icon {
	display: flex;
	align-items: center;
	color: #727272;

	svg {
		width: 15px;
		height: auto;

		path {
			stroke: #727272;
			stroke-width: 1.5;
		}
	}
}

.search-bar-submit-button {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
	color: #eb6400;
	background-color: transparent;
	align-items: center;
	padding: 0.5rem;

	> svg {
		width: 1rem;
		height: auto;
	}
}

.search-bar-input-background {
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 30;
	width: 100%;
	height: 88px;
}

.search-bar-input {
	width: 100%;
	padding: 8px 50px 7px !important; // Need to do this hack because we have global styles defined later in the stylesheet
	font: inherit;
	color: #707070 !important; // Need to do this hack because we have global styles defined later in the stylesheet
	border: 0;
  border-radius: 0 !important; // Need to do this hack because we have global styles defined later in the stylesheet
	border-bottom: 2px solid #eb6400;
	background-color: #fff !important; // Need to do this hack because we have global styles defined later in the stylesheet

	&:focus {
		outline: 0;
	}
}

.search-bar-box {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 6;
	width: 100%;
	//height: 100vh;
	color: #000;
	text-align: center;
	overflow-y: auto;

  &.is-full-height {
    height: 100vh;
  }
}

.search-bar-dropdown {
	position: relative;
	z-index: 1;
	min-height: 300px;
	padding: 1rem;
	padding-top: 150px;
	background-color: #fff;
}

.search-bar-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000, 0.6);
	z-index: 1;
}

// Link to search page
.search-bar-show-more {
	display: inline-block;
	color: #eb6400;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
	margin-top: clamp(1.5rem, 5vmin, 3rem);

	&:hover {
		text-decoration: underline;
	}
}

// Search results
// --------------

.search-results {
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: 700px) {
		padding: 15px 20px;
	}
}

// Text styles
.search-results-label {
	color: #eb6400;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 20px;
}

.search-results-title {
	color: #555;
	font-size: 16px; // Fallback for clamp
	font-size: clamp(16px, calc(8px + 1vmin), 20px);
	font-weight: 700;
	margin-bottom: 0.3em;
}

.search-results-subtitle {
	margin-bottom: 0.5rem;
	color: #999;
	font-size: 14px;
}

.search-results-text {
	color: #555;

	> :last-child {
		margin-bottom: 0;
	}
}

.search-results-link {
	display: block;

	&:hover {
		text-decoration: underline;
	}
}

// .search-results-empty {
// 	text-align: center;
// }

// Larger click area
.search-results-cover-link {
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: background-color 300ms ease;
	}

	&:hover {
		text-decoration: underline;
	}
}

// Results
// .search-results-results {
// 	max-width: $large;
// 	margin: 20px auto 60px;

// 	@media (min-width: 600px) {
// 		margin: 60px auto 120px;
// 	}

// 	[aria-hidden="true"] {
// 		display: none;
// 	}

// 	@media (max-width: 699px) {
// 		padding: 15px 20px;
// 	}
// }

// .search-results-results > .is-narrow {
// 	max-width: $small;
// 	margin-left: auto;
// 	margin-right: auto;
// }

// Layout: Rows & columns
.search-results-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -1rem;
	text-align: left;
}

.search-results-col {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	padding: 1rem;
	margin-bottom: 1.5rem;

	&.is-grow {
		flex-grow: 2;
	}
}

// Layout: Group
.search-results-group {
	ol {
		margin: 0;
		padding: 0;
	}

	> ul,
	> ol {
		margin-top: 0.5rem;
	}

	+ .search-results-group {
		margin-top: 3rem;
	}
}

// Layout: Vertical Stack
.search-results-vstack > * + * {
	margin-top: clamp(1.5rem, 5vmin, 4rem);
}

.search-results-vstack.is-category > * + * {
	margin-top: 0.5rem;
}

// Layout: Grid
.search-results-grid {
	display: flex;
	flex-wrap: wrap;

	> * {
		margin: 10px;
		width: calc(100% - 20px);

		@media (min-width: 400px) {
			width: calc(100% / 2 - 20px);
		}

		@media (min-width: 600px) {
			width: calc(100% / 3 - 20px);
		}

		@media (min-width: 900px) {
			width: calc(100% / 4 - 20px);
		}
	}
}

// Item style: Card
.search-results-card {
	display: flex;
	flex-wrap: nowrap;
	position: relative;
	// align-items: center;

	&.is-horizontal {
		flex-direction: row;

		> * + * {
			margin-left: clamp(1.5rem, 5vmin, 3rem);
		}
	}

	&.is-vertical {
		flex-direction: column;

		> * + * {
			margin-top: 1rem;
		}
	}

	&.has-border {
		background-color: #fff;
		padding: 16px;
		color: #000;
		border: 4px solid #f4f4f4;

		@media (min-width: 600px) {
			padding: 24px;
		}

		@media (min-width: 900px) {
			padding: 32px;
		}
	}

	.search-results-media.is-small {
		flex-shrink: 0;
	}
}

.search-results-media {
	position: relative;

	&.is-small {
		min-width: 50px;
		width: 50px; // Fallback for vmin unit
		width: 10vmin;
		max-width: 150px;
		min-height: 50px;
		height: 50px; // Fallback for vmin unit
		height: 10vmin;
		max-height: 150px;
	}

	> img {
		width: 100%;
		height: 100%;
		object-fit: contain;

		&.is-circle {
			border-radius: 25px;
			object-fit: cover;
		}

		&.is-grayscale {
			filter: grayscale(1);
		}
	}

	&.is-ratio-3-4 {
		padding-top: 75%;

		> img {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
