//
// header.scss
// Dashkit component
//

// Header
//
// General styles

// .header {
//   margin-bottom: $header-margin-bottom;
// }

.header-img-top {
  width: 100%; height: auto;
}

.header-body {
  padding-top: $header-spacing-y;
  padding-bottom: $header-spacing-y;
  border-bottom: $header-body-border-width solid $header-body-border-color;
}

.header.bg-dark .header-body,
.header.bg-hero .header-body {
  border-bottom-color: $header-body-border-color-dark;
}

.header-footer {
  padding-top: $header-spacing-y;
  padding-bottom: $header-spacing-y;
}

.header-pretitle {
  text-transform: uppercase;
  letter-spacing: .08em;
  color: $text-muted;
}

.header-title {
  margin-bottom: 0;
}

.header-subtitle {
  margin-top: map-get($spacers, 2);
  margin-bottom: 0;
  color: $text-muted;
}

.header-tabs {
  margin-bottom: -$header-spacing-y;
  border-bottom-width: 0;

  .nav-link {
    padding-top: $header-spacing-y;
    padding-bottom: $header-spacing-y;
  }
}
