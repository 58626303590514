// Credit: Nicolas Gallagher and SUIT CSS.

@use "sass:math";

.embed-responsive {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@each $key, $ratio in $embed-responsive-aspect-ratios {
  .embed-responsive-#{$key} {
    &::before {
      padding-top: percentage(math.div(map-get($ratio, y), map-get($ratio, x)));
    }
  }
}
