.scroll-view {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 20vh;
  -webkit-overflow-scrolling: touch;
}

//Overides
.header-title {
  font-weight: 600;
}

.flatpickr-disabled {
  opacity: 0.1;
}

.modal-search {
  position: relative;
  z-index: 9999;
}

.btn-outline-secondary {
  color: inherit;
}

.card-pagination.pagination-tabs {
  position: relative;
  top: 1px;
}

.pointer-none {
  pointer-events: none;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.top-50 {
  top: 50% !important;
}

.end-0 {
  right: 0 !important;
}

.appearence-none {
  appearance: none !important;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
}

.has-action-footer {
  position: relative;
  padding-bottom: 80px;
}

.footer-actions {
  left: 0;
  bottom: 0;
  z-index: 20;
  margin: 0 auto;
  box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);

  // > div {
  //   box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);
  // }
}

.is-deleted {
  background-color: red;

  button,
  .dropdown {
    visibility: hidden;
  }
}

.font-xs {
  font-size: 14px;
}

.accordion-item {
  color: $black;
  transition: color 0.2s;

  &:not(:first-child) {
    border-top: 1px solid $border-color;
  }

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  .accordion-item-icon {
    transition: transform 0.2s;
  }

  &:hover {
    .order-item-name {
      color: $primary;
    }

    .accordion-item-icon {
      transform: translateX(3px);
    }
  }
}

.increment-decrement-box {
  border: 1px solid $gray-400;
  height: 50px;
  padding: 0 15px;
  border-radius: 0.375rem;
}

.btn-disabled,
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
  border-color: $border-color;
  background-color: $read-only-bkg;
}

.text-is-disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}

.text-gray-300 {
  color: $gray-300;
}


.top-shadow {
  box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);
}

.bkg-gray-100 {
  background-color: $gray-100;
}

.bkg-gray-200 {
  background-color: $gray-200;
}

.bkg-gray-300 {
  background-color: $gray-300;
}

.bg-hll-empty-screen {
  background-color: $hll-success;
}

.readonly {
  @extend .disabled;
}

.horizontal-scroll-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-outline-secondary {
  &.btn-white {
    &:hover {
      color: #707070;
    }
  }
}
