.hll-breadcrumb {
  .scrollable-breadcrumb {
    @include media-breakpoint-down(lg) {
      position: relative;
      margin: 0 auto;
      max-width: 100%;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-mask-image: linear-gradient(90deg,transparent 0,#000 1rem,#000 calc(100% - 1rem),transparent);
      mask-image: linear-gradient(90deg,transparent 0,#000 1rem,#000 calc(100% - 1rem),transparent);
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: inline-block;
    padding: 0;
    white-space: nowrap;
    @include media-breakpoint-down(lg) {
      &:first-child {
        padding-left: 0.5rem;
      }
    }
  }
}
