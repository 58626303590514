.customer-machine-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 340px), 1fr));
  grid-gap: 1rem;

  &__item {
    position: relative;
    // aspect-ratio: auto 4/2;
    z-index: 0;

    &--first {
      aspect-ratio: 4/2;
    }

    &.card {
      margin-bottom: 0;
    }

    &:not(.no-shadow) {

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      &::after {
        opacity: 1;
        border-radius: 4px;
        box-shadow: $card-box-shadow;
        content: "";
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity .2s ease-in-out;
        width: 100%;
        z-index: -1;
      }
    }


    &.is-loading {
      aspect-ratio: unset;
      height: 100%;
    }

    .machine-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;

      &__item {
        aspect-ratio: 1;
        overflow: hidden;

        figure {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(0.5);
          }
        }
      }
    }
  }
}

// .machine-list-modal-sheet {
//   position: absolute !important;
//   overflow-x: auto;

//   @include media-breakpoint-up(xl) {
//     position: fixed !important;
//   }

//   &__wrapper {
//     display: flex;
//     flex-direction: column;
//     height: 100%;

//     @include media-breakpoint-up(xl) {
//       flex-direction: row;
//     }
//   }

//   &__list {
//     display: flex;
//     flex-direction: column;
//     gap: 1rem;
//     margin-top: 1rem;

//     @media (min-width: 990px) {
//       margin-top: 0;
//     }
//   }

//   &__column {
//     height: 100%;

//     .is-scrollable {

//         overflow-x: auto;
//         -webkit-overflow-scrolling: touch;
//         margin-left: 0;
//         margin-right: 0;
//         //max-height: calc(100vh - 57px);
//         padding-bottom: 57px;

//     }


//     .machine-list {
//       figure {
//         text-align: center;

//         @include media-breakpoint-up(lg) {
//           aspect-ratio: 4/3;
//           align-self: flex-start;
//         }

//         img {
//           width: 100%;
//           height: 100%;
//           max-width: 50%;
//           object-fit: contain;

//           @include media-breakpoint-up(md) {
//             max-width: 75px;
//           }

//           &.logo {
//             object-fit: contain;
//           }
//         }
//       }
//     }
//   }

//   &__actions {
//     position: sticky;
//     bottom: 0;
//     z-index: 8;
//     background-color: $white;
//   }
// }
