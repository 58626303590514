.google-map {
  width: 100%;
  height: 300px;

  .pin-icon > span {
    color: $primary;
    font-size: 2rem;
  }
}
input[data-reach-combobox-input] {
  position: relative;
  background-color: $white;
  z-index: 1000;
}

.google-maps-suggestions {
  margin: 0;
  padding: 0;
  position: absolute;
  list-style: none;
  top: 100%;
  width: 100%;
  background-color: $white;
  z-index: 999;
  list-style: none;

  li {
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    &:first-child {
      border-top: none;
      margin-top: -2px;
      padding-top: 2px;
    }

    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }
}
