.machine-item {
  color: $black;
  transition: color 0.2s;

  &:not(:first-child) {
    border-top: 1px solid $border-color;
  }

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  .machine-item-icon {
    transition: transform 0.2s;
  }

  &:hover {
    .machine-item-name {
      color: $primary;
    }

    .machine-item-icon {
      transform: translateX(3px);
    }
  }

  &.disabled {
    border-left: 3px solid red;
    background-color: rgba($red, 0.1) !important;
  }
}

.machine-input {
  border: 1px solid $gray-300;
  height: 50px;
  padding: 0 15px;
}

.machine-item-availability {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 0;
  background-color: $gray-300;
}

.availability {
  &--control {
    background-color: $on-control;
  }

  &--available {
    background-color: $available;
  }

  &--not-available {
    background-color: $not-available;
  }
}

.machine-footer {
  left: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 20;
  box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);

  // > div {
  //   box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);
  // }

  &--email-actions {
    border-bottom: 1px solid $gray-300;
  }
}

.machines-form {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[name="search"] {
    background-color: $white;
  }

}

.machine-transfer-item-wrap {
  &:not(:first-child) {
    margin-top: 2rem;
  }
}

.machine-transfer-item {
  border: 1px solid $gray-300;
  padding: 1rem 1.25rem;
  background: $white;
  transition: background 0.2s;

  &.is-disabled .machine-transfer-item-icon {
    opacity: 0.3;
  }

  &.is-selected {
    background: rgba($black, 0.02);
  }

  + .machine-transfer-item {
    border-top: 0;
  }
}

.machine-transfer-depot {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }
}
