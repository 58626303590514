.maintenance {
  padding: 0 1.25rem;

  &__main {
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    height: 1px;

    .card {
      width: 100%;
      max-width: 420px;
    }
  }

  .logo {
    width: auto;
    height: 64px;
  }
}
