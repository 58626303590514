// Badge Mixins
//
// This is a custom mixin for badge-#{color}-soft variant of Bootstrap's .badge class

@mixin badge-variant-soft($bg, $color) {
  color: $color;
  background-color: $bg;

  &[href]:hover,
  &[href]:focus {
    color: $color;
    text-decoration: none;
    background-color: darken($bg, 5%);
  }
}
