.machineItem {
  .machine-data-row {
    .divider {
      &--top {
        border-top: 1px solid $border-color;
      }
      &--bottom {
        border-bottom: 1px solid $border-color;
      }
    }

    .btn-group {
      button {
        font-size: 0.75rem;
        border-radius: 3px;

        @include media-breakpoint-up(sm) {
          font-size: inherit;
        }


        &.availability {
          &--control {
            background-color: $on-control;
          }

          &--available {
            background-color: $available;
          }

          &--not-available {
            background-color: $not-available;
            color: $white;
          }

          &:disabled,
          &.disabled {
            opacity: 1 !important;
          }
        }
      }

      .btn + .btn {
        margin-left: -1px !important;
      }

      .btn:disabled,
      .btn.disabled {
        border-color: $gray-300;
      }
    }
  }

  .machine-footer {
    left: 0;
    bottom: 0;
    z-index: 20;
    margin: 0 auto;
    box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);

    // > div {
    //   box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);
    // }

    &--email-actions {
      border-bottom: 1px solid $gray-300;
    }
  }
}

.alert-inspection {
  background-color: $machine-status-info-bkg;
}

.alert-inspection--warning {
  background-color: $machine-status-warning-bkg;
}

.info-inspection,
.alert-inspection {
  color: $machine-status-info-txt;
}

.inspection-note {
  //
}
