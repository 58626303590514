.category-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(0.5rem, 2vmin, 1rem);
  margin: 0 auto;
  padding: 0;
  list-style: none;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.category-grid-card {
  position: relative;
  z-index: 0;
  background-color: #000;
  grid-column: span 1;
  aspect-ratio: 16/9;
  border-radius: 4px;
  overflow: hidden;

  @media (min-width: 1200px) {
    aspect-ratio: 1/1;
  }

  &.is-loading {
    background-color: transparent;
  }

  &::after {
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 0;
    opacity: .2;
    position: absolute;
    top: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    z-index: -1;
  }

  a.is-clickable {
    cursor: pointer;
    outline: none;

    &:hover {
      color: #000;
    }

    &::after {
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }
}

.category-grid-card-title {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  padding: clamp(0.5rem, 10%, 1.5rem);
  margin-bottom: 0;
  font-size: clamp(0.75rem, calc(0.5rem + 1vmin), 1rem);
}

.category-grid-card-media-frame {
  position: relative;
  aspect-ratio: 16/9;
  height: 100%;
  width: 100%;
  margin: 0;

  @media (min-width: 1200px) {
    aspect-ratio: 1/1;
  }

  img,
  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
  }
}
