.single-machine-model {
  &__image-wrapper {
    width: 100%;
    height: 350px;
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      height: 100%;
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
  }

  &__content {
    padding: 20px;
  }
}

.machine-filter {
  &__image-wrapper {
    aspect-ratio: 1/1;

    > div {
      width: 100%;
      height: 100%;
    }

    img {
      height: 76px;
      object-fit: contain;
      width: 80px;
    }
  }
}
