//https://github.com/includable/react-update-popup

.update-notification-popup {
  position: fixed !important;
  background-color: #fff !important;
  box-shadow: $card-box-shadow;
  z-index: 9999 !important;
  padding: 2rem;
  border: 1px solid $card-border-color;
  border-radius: $card-border-radius;

  &.bottom-right {
    bottom: 1rem;
    right: 1rem;
  }

  &.center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
