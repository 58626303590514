#{ $all-text-inputs } {
  display: block;
  width: 100%;
  padding: 1em 1.25em;
  font-weight: 700;
  color: inherit;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
    border-color: $border-color;
    background-color: $read-only-bkg;

    &[readonly] {
      opacity: 0.5 !important;
      border-color: $border-color;
      background-color: $read-only-bkg;
    }
  }

  &.no-focus {
    &:focus {
      border-color: $border-color;
    }
  }
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;

  &.machine-list-select {
    color: $white;
    background-color: $hll-orange;
    background-image: none !important;
    background-size: 16px 16px;
    background-position: right 1rem center;
    border-color: $hll-orange;
    cursor: pointer;
    border: none !important;

    option,
    optgroup {
      color: $black;
      background-color: $white;
      border: none !important;
    }
  }

  &.disabled {
    opacity: 0.5 !important;
    border-color: $border-color;
    background-color: $read-only-bkg;

    &[readonly] {
      opacity: 0.5 !important;
      border-color: $border-color;
      background-color: $read-only-bkg;
    }
  }

  &.no-focus {
    &:focus {
      border-color: $border-color;
    }
  }
}

button {
  &.disabled {
    opacity: 0.5 !important;
  }
}

.form-control-modal {
  height: 42px !important;
  padding: 0 15px !important;
}

button {
  border: 0;
}

.field {
  &__label {
    @include font-size(12px);
    letter-spacing: 0.1em;
    font-weight: 700;
  }
}

.list-checkbox-theme {
  width: 22px;
  height: 22px;
  //background-color: $white;
  margin-top: 1px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 767px) {
  input {
    font-size: 16px;
  }
}

.text-read-only-box {
  display: block;
  font-weight: 400;
  color: inherit;
  padding: 1em 1.25em;
  border-color: $border-color;
  background-color: $read-only-bkg;
  min-height: 56px;
  border-radius: $card-border-radius;
  opacity: 0.5;
}

.checkbox-rows {
  border-bottom: 1px solid $border-color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}


// SVG icons for checkboxes
.form-check {
  // Checkbox if not a switch
  &:not(.form-switch) {

    .form-check-input {
      width: 1.25rem !important;
      height: 1.25rem !important;
    }

    // SVG icons for checkboxes
    .form-check-input:checked[type="checkbox"] {
      background-image: url("../../icons/check-circle-selected-filled.svg") !important;
      background-size: 1.25rem !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }

    .form-check-input:focus:not(:checked) {
      background-image: url("../../icons/check-circle.svg") !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }

    .form-check-input[type="checkbox"] {
      background-image: url("../../icons/check-circle.svg") !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }

  }

  &.form-switch {
    .form-check-input {
      background-color: $gray-400;
      &:checked {
        background-color: $hll-orange;
      }
    }
  }
}
