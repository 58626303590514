.nav-off-canvas {
  top: 0;
  right: 0;
  transform: translateX(100%);
  width: 100%;
  will-change: transform;
  transition: transform 0.3s;
  z-index: 1500 !important;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    width: 75%;
  }

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 450px;
  }

  &.is-open {
    transform: translateX(0);
  }

  .nav-off-canvas-inside {
    padding-top: 1rem;
  }
}

.nav-off-canvas-backdrop {
  visibility: hidden;
  opacity: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 499;
  transition: opacity 0.3s, visibility 0.3s;

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }
}

.nav-off-canvas-header {
  padding-bottom: 1rem;
  border-bottom: 1px solid $border-light-color;
}

.nav-off-canvas-body {
  position: absolute;
  left: 0;
  right: 0;
  height: calc(100% - 160px);
  overflow: auto;
  padding-bottom: 50px;
  transform: translateX(0);
  will-change: transform;
  transition: transform 0.3s;

  &.is-secondary {
    transform: translateX(-100%);
  }

  &.secondary {
    opacity: 0;
    transform: translateX(100%);
    will-change: transform;
    transition: transform 0.3s;

    &.company-profile-secondary-is-active {
      opacity: 1;
      transform: translateX(0);
    }

    li:not(:last-child) {
      margin-bottom: $spacer * 0.5;
    }
  }

  .goBack {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
  }
}

.nav-off-canvas-header,
.nav-off-canvas-body {
  padding-left: 1rem;
  padding-right: 1rem;

  @include media-breakpoint-up(sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.nav-off-canvas-nav-list {
  list-style: none;
  padding: 0;

  li:not(:last-child) {
    margin-bottom: $spacer;
  }
}

.nav-user-management {
  padding: 25px 1rem;
  margin-top: 25px;
  margin-left: -1rem;
  margin-right: -1rem;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  @include media-breakpoint-up(sm) {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-up(lg) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.nav-off-canvas-footer {
  height: 70px;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  padding-left: 24px;
  padding-right: 24px;

  @include media-breakpoint-up(sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.nav-user-management {
  background-color: $body-bg-color;
}

.link-site-nav {
  color: rgba(0, 0, 0, 0.75);
}

button.link-site-nav {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
}

.nav-off-canvas-image-gallery {
  position: relative;
  overflow: hidden;
  background-color: $white;
  border-bottom: 1px solid $border-light-color;

  img {
    aspect-ratio: 16 / 9;
    object-fit: contain;
  }
}

.nav-off-canvas-data-row {
  padding: 1rem;
  border-top: 1px solid $border-light-color;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid $border-light-color;
  }

  &.notice {
    border-top: none;
    padding-top: 0;
  }
}


