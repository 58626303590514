.product-card {
  position: relative;
  z-index: 0;
  background-color: #fff;

  &::after {
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 0;
    opacity: .2;
    position: absolute;
    top: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    z-index: -1;
  }

  a.is-clickable {
    cursor: pointer;
    outline: none;

    &:hover {
      color: #000;
    }

    &::after {
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.product-card-image-frame {
  position: relative;
  aspect-ratio: 5/4;
}

.product-card-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}