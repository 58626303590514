.table {
  thead th {
    background-color: $body-bg-color-light;
  }

  td,
  th {
    border-color: $border-color;
  }

  tr:hover td {
    background-color: $body-bg-color-light;
    background-image: none;    
  }
}

.btn-table-header-sort {
  padding: 0;
  background-color: transparent;
  letter-spacing: 0.08em;
  font-weight: 600;
  text-transform: uppercase;
  color: $hll-dark-gray;

  &::after {
    top: 1px;
    position: relative;
  }
}
