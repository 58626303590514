.machine-search {
  input[type=search] {
    background-color: $gray-600;
    border: 1px solid $gray-600;
    height: 40px;
  }

  &--result {
    position: absolute;
    left: 0;
    right: 0;
    background-color: $white;
    min-height: 300px;
    z-index: 4;
  }

  &--item {
    .item-image-wrapper {
      width: 50px;
      height: 50px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

  }
}
