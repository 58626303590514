.filter-grayscale {
  filter: grayscale(1);
}

pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid $primary;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

/* For all <code> */
code {
  font-family: MyFancyCustomFont, monospace;
  font-size: inherit;
}

/* Code in text */
p > code,
li > code,
dd > code,
td > code {
  background: #ffeff0;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem 0.2rem;
  border-radius: 0.2rem;
}

pre code {
  display: block;
  background: none;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  max-width: 100%;
  min-width: 100px;
  padding: 0;
}

.text-orange {
  color: #eb6400 !important;
}

.border-color-primary {
  border: 1px solid rgba(235, 100, 0, 0.375) !important;
}

.gap-3 {
  gap: 0.75rem;
}