.notifications {
  .expandable-content {
    display: none;

    &.is-active {
      display: block;
    }
  }

  .alert-danger a {
    color: $white;
    font-weight: 700;
    text-decoration: underline;
  }

  .alert-info a {
    color: $white;
    font-weight: 700;
    text-decoration: underline;
  }

  .alert-warning a {
    color: $black;
    font-weight: 700;
    text-decoration: underline;
  }

  .alert-primary a {
    color: $white;
    font-weight: 700;
    text-decoration: underline;
  }
}
