.modal {
  .modal-dialog {
    &[fullscreen="true"] {
      width: 100vw;
      height: 100%;
      margin: 0 auto;

      .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
      }

      .footer-actions {
        left: 0;
        right: 0;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }

      .modal-sheet {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .modal-content {

    .form-control {
      border-color: $gray-400 !important;
    }

    .react-datepicker-wrapper [type="text"] {
      border-color: $gray-400 !important;
    }

  }
}


.modal-order,
.modal-machine,
.modal-scrollable {
  .modal-header {
    display: block;
  }

  &.transport-order-modal .modal-body {
    background-color: $white;
  }

  &.block-scroll {
    overflow: hidden !important;
    .is-scrollable {
      overflow: hidden !important;
      -webkit-overflow-scrolling: none;
    }
  }

  .modal-body {
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-x: auto;

    textarea {
      resize: none;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
  }

  .is-scrollable {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    max-height: calc(100vh - 195px);

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba($border-color, 0.3);
      background-color: $border-color;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: $border-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
    }
  }

  .modal-content {
    min-height: 98vh;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        height: -webkit-fill-available;
        min-height: initial;
      }
    }
  }

  &.newrental-modal {
    .is-scrollable {
      max-height: calc(100vh - 276px);
    }
    .modal-footer {
      height: 140px;
    }
  }
}

.modal-scrollable.modal-no-footer .is-scrollable {
  max-height: calc(100vh - 110px);

  &.no-search {
    max-height: calc(100vh - 57px);
  }
}

.modal-scrollable.modal-no-footer.no-search .is-scrollable {
  max-height: calc(100vh - 57px);
}

.modal-header,
.modal-footer {
  border: 0;
}

.backdrop-newrental-inspection {
  z-index: 1050;
}

.modal-md {
  .modal-header {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.modal-disabled {
  opacity: 0.5;
  pointer-events: none;
  transition: opacity 0.2s;
}

.modal.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
}

.modal.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}
