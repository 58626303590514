.machine-category-menu {
  &__item {
    font-weight: 700;

    &.parent {
      border-bottom: 1px solid $gray-300;
    }

    &.child {
      font-size: 1rem;
      font-weight: 400;

      &:last-child {
        padding-bottom: 0.75rem;
      }
    }
  }

}

// .machine-category-scrollable {
//   @include media-breakpoint-down(lg) {
//     position: relative;
//     margin: 0 auto;
//     max-width: 100%;
//     overflow: hidden;
//     overflow-x: auto;
//     white-space: nowrap;
//     -webkit-mask-image: linear-gradient(90deg,transparent 0,#000 1rem,#000 calc(100% - 1rem),transparent);
//     mask-image: linear-gradient(90deg,transparent 0,#000 1rem,#000 calc(100% - 1rem),transparent);
//     -ms-overflow-style: none;
//     scrollbar-width: none;
//   }

//   &::-webkit-scrollbar {
//     //display: none;
//   }
// }

.machine-category-grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 1rem;

  &.dashboard {
    grid-template-columns: repeat(6, 1fr);

    @include media-breakpoint-down(xl) {
      display: grid;
      grid-gap: 1rem;
      grid-auto-flow: column;
      grid-auto-columns: 300px;

      position: relative;
      margin: 0 auto;
      max-width: 100%;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-mask-image: linear-gradient(90deg,transparent 0,#000 1rem,#000 calc(100% - 1rem),transparent);
      mask-image: linear-gradient(90deg,transparent 0,#000 1rem,#000 calc(100% - 1rem),transparent);
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin-left: -0.5rem;

      .machine-category-grid__item {
        &:first-child {
          margin-left: 0.5rem;
        }
      }
    }

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(2, 1fr);
      overflow-x: auto;
    }
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  &.is-sub-categories {
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1,minmax(0,1fr));

      .machine-category-grid__item {
        aspect-ratio: 5;
        justify-content: center;
        padding-left: 8rem !important;

        &.is-sub-category {
          figure {
            bottom: 0;
            left: 0;
            padding: 1rem;
            position: absolute;
            top: 0;
            width: 8rem;
            z-index: -1;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    aspect-ratio: 1;
    background-color: $black;
    z-index: 0;

    &.is-loading {
      background-color: transparent;
    }

    &:hover {
      figure {
        img {
          opacity: 0.6;
        }
      }
    }

    &::after {
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(0,0,0,.1);
      content: "";
      height: 100%;
      left: 0;
      opacity: .2;
      position: absolute;
      top: 0;
      transition: opacity .2s ease-in-out;
      width: 100%;
      z-index: -1;
    }

    &.is-loading {
      aspect-ratio: unset;
      background-color: transparent;

      &::after {
        display: none;
      }
    }

    .is-clickable {
      cursor: pointer;
      outline: none;
      z-index: -1;

      &::after {
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    header {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      h2 {
        @include media-breakpoint-down(lg) {
          font-size: 1rem;
        }
      }
    }

    figure {
      flex: 1 1 0%;
      overflow: hidden;

      img {
        display: block;
        height: 100%;
        object-fit: cover;
        opacity: .5;
        transition: opacity .3s ease-in-out;

        &.logo {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }

    &__title {
      position: absolute;
      top: 32px;
      left: 24px;
      z-index: 3;
      color: $white;
      text-align: left;
    }

    &__link {
      position: absolute;
      right: 25px;
      bottom: 17px;
      z-index: 3;
      color: $white;
      font-size: 0.85rem;
      line-height: normal;
      text-decoration: none;
    }

    &.is-sub-category,
    &.is-machine-item {
      background-color: $white;

      figure {
        flex: 1 1 0%;
        overflow: hidden;
      }

      a {
        color: $black;
      }

      &:hover {
        figure {
          img {
            opacity: 1;
          }
        }
      }

      img {
        display: block;
        height: 100%;
        object-fit: contain;
        opacity: 1;

        &.logo {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      footer {
        font-size: .875rem;
      }
    }

    &.is-machine-item {
      @include media-breakpoint-down(lg) {
        aspect-ratio: unset;
      }

      figure {
        order: -1;

        img {
          aspect-ratio: 1;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }

          &.logo {
            @include media-breakpoint-down(lg) {
              width: 100%;
              height: 100%;
              transform: none !important;
            }
          }
        }
      }
    }
  }
}

