.card {
  border-color: $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: none;
}

.card-header-title {
  font-size: 1rem;
  font-weight: 700;
}

.depot-card .list-group-item{
  border-radius: 0;
}

// Card used in modal-split-sheet__list
.cart-card {
  display: grid;
  grid-auto-flow: row dense;
  gap: 1rem 1rem;
  grid-template-columns: repeat(6, 1fr);
  align-items: start;
  padding: 1rem;
  background-color: $white;
  border-block: 1px solid $card-border-color;
  text-align: left;

  @media (min-width: 990px) {
    border-inline: 1px solid $card-border-color;
    border-radius: $card-border-radius;
  }
}

.cart-card__image {
  position: relative;
  grid-column: span 1;
  margin-bottom: 0;
  // aspect-ratio: 1/1;
  width: auto;
  min-width: 75px;
  height: 75px;

  @media (min-width: 600px) {
    grid-row: 1 / 3;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.cart-card__details {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem 1.5rem;
  align-items: start;
  
  @media (min-width: 320px) {
    grid-column: span 5;
  }

  @media (min-width: 600px) {
    > * {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

.cart-card__title {
  width: 100%;
  font-size: 1rem;
  margin-right: auto;
  margin-bottom: 0;

  @media (min-width: 600px) {
    width: auto;
  }
}

.cart-card__actions {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 0.5rem;

  @media (min-width: 600px) {
    justify-content: end;
    max-width: 15rem;
  }
}

.cart-card__info {
  grid-column: span 6;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 600px) {
    flex-direction: row;
  }

  > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  @media (min-width: 600px) {
    grid-column: 2 / span 5;
  }
}

.cart-card__price {
  flex-grow: 1;
  white-space: nowrap;
}

.cart-card__accordion-trigger {
  background-color: $body-bg-color;

  &.is-open {
    background-color: $gray-900;
    color: $white;

    svg path {
      stroke: $white;
    }
  }
}

.cart-card--accordion-panel {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: 1 / -1;
  margin-inline: -1rem;
  padding-top: 1rem;
  padding-inline: 1rem;
  border-top: 1px solid $card-border-color;
}
