.order-item {
  color: $black;
  transition: color 0.2s;

  &:not(:first-child) {
    border-top: 1px solid $border-color;
  }

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  .order-item-icon {
    transition: transform 0.2s;
  }

  &:hover {
    .order-item-name {
      color: $primary;
    }

    .order-item-icon {
      transform: translateX(3px);
    }
  }

  &.direct-payment {
    //
  }
}

.order-input {
  border: 1px solid $gray-300;
  height: 50px;
  padding: 0 15px;
}

.order-footer {
  left: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 20;
  box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);

  // > div {
  //   box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);
  // }

  &--email-actions {
    border-bottom: 1px solid $gray-300;
  }
}
