.onboarding-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100vw;
  height: 100vh;
  background-color: $black;
}

.onboarding {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 1050;
  background-color: $white;

  .close-button {
    width: 30px;
    height: 30px;
    z-index: 200;
    top: 10px;
    right: 10px;
  }

  @include media-breakpoint-down(md) {
    .carousel-item {
      &.active {
        > div {
          display: flex !important;
          flex-direction: column !important;
        }
      }
    }

    .onboarding-footer {
      position: fixed;
      width: 100%;
      bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    width: 60%;
    height: 651px;
    max-width: 700px;
    background-color: transparent;
  }

  .carousel-item {
    header,
    section,
    footer {
      background-color: $white;
    }
  }
}

.onboarding-header {
  overflow: hidden;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.onboarding-image-area {
  overflow: hidden;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;

  .aspect-ratio-box {
    height: 0;
    overflow: hidden;
    padding-top: 300px / 700px * 100%;
    background: $primary;
    position: relative;
  }
  .aspect-ratio-box-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100%;
  }

  .flexbox-centering {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      min-width: 100%;
    }
  }
}

.onboarding-content {
  overflow-x: auto;
  height: 100%;

  @include media-breakpoint-up(md) {
    height: 255px;
  }
}

.onboarding-sub-title {
  font-weight: 600;
}

.onboarding-indicator {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: $indicators;
  margin-right: 4px;
  padding: 0 !important;

  &:last-child {
    margin-right: 0;
  }
}

// .onboarding-arrow {
//   border-radius: 50%;
//   width: 20px;
//   height: 20px;
//   background-color: $indicators;
//   padding: 0 !important;

//   i {
//     line-height: 1;
//   }
// }
