.modal-sheet {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  min-height: 30vh;
  background-color: $white;
  z-index: 1100;
  box-shadow: 0 -3px 4px 0 rgba($border-color, 0.5);
  margin: 0 auto;

  &.full-height {
    height: 100%;
  }

  .virtual-keyboard-active & {
    bottom: env(keyboard-inset-height, 0);
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.rounded-top-big {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    min-height: inherit;
  }

  &.modal-sheet-max-width {
    max-width: 600px;
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.modal-sheet__header {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: $white;
}

// Modal split sheet
// -----------------

.modal-split-sheet {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  background-color: $white;
  overflow-y: auto;
}

.modal-split-sheet__header {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: $white;
  border-bottom: 1px solid $border-color;
}

.modal-split-sheet__close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-right: -0.5rem;
  border: 0;
  background-color: transparent;
}

.modal-split-sheet__wrapper {
  display: flex;
  flex-direction: column;
  background-color: $border-color; // Creates the vertical 1px border between columns using gap
  gap: 1px;
  height: 100%;
  min-height: 100%;

  .tab-content {
    height: 100%;

    > *[role="tabpanel"] {
      height: 100%;

      > * {
        height: 100%;

        &.auto {
          height: auto;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    height: auto;

    > * {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

.modal-split-sheet__column {
  margin-top: 1rem;
  background-color: $body-bg-color;

  + .modal-split-sheet__column {
    background-color: $white;
  }

  @media (min-width: 990px) {
    margin-top: 0;
  }

  #uncontrolled-tab-tabpane-overview & {
    margin-top: 0;
  }
}

.modal-split-sheet__list {
  display: flex;
  flex-direction: column;
  background-color: $body-bg-color;
  gap: 1rem;
}

.modal-split-sheet__actions {
  position: sticky;
  bottom: 0;
  z-index: 8;
  background-color: $white;
}
