.header {
  &--navigation {
    a {
      position: relative;
      color: $white;
      font-weight: 700;

      &:hover,
      &:focus {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          left: 0;
          top: 100%;
          transform: translateY(-3px);
          background-color: $hll-orange;
        }
      }

      &.state {
        &--active {
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            left: 0;
            top: 100%;
            transform: translateY(-3px);
            background-color: $hll-orange;
          }
        }
      }
    }
  }

  .logo {
    width: auto;
    height: 40px;
  }
}

.header-main-title {
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  left: 50%;
  transform: translateX(-50%);
}
